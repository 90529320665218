import { DropDownItem } from "../../forms/forms";

export class OrderBy {
  selectedOrderBy: string;
  options: DropDownItem[] = [
    {
      label: "Most viewed",
      value: "viewsTotal desc",
      action: () => {
        this.selectedOrderBy = "viewsTotal desc";
      },
    },
    {
      label: "Location: closest",
      value: null,
      action: () => {
        this.selectedOrderBy = "sortlocation";
      },
    },
    {
      label: "Year: newest to oldest",
      value: "year desc",
      action: () => {
        this.selectedOrderBy = "year desc";
      },
    },
    {
      label: "Year: oldest to newest",
      value: "year asc",
      action: () => {
        this.selectedOrderBy = "year asc";
      },
    },
    {
      label: "Recently added",
      value: "createdDateTime desc",
      action: () => {
        this.selectedOrderBy = "createdDateTime desc";
      },
    },

    {
      label: "Rental rate: low to high",
      value: "rentalRate asc",
      action: () => {
        this.selectedOrderBy = "rentalRate asc";
      },
    },
    {
      label: "Rental rate: high to low",
      value: "rentalRate desc",
      action: () => {
        this.selectedOrderBy = "rentalRate desc";
      },
    },
    {
      label: "Buy it now: low to high",
      value: "buyItNowPrice asc",
      action: () => {
        this.selectedOrderBy = "buyItNowPrice asc";
      },
    },
    {
      label: "Buy it now: high to low",
      value: "buyItNowPrice desc",
      action: () => {
        this.selectedOrderBy = "buyItNowPrice desc";
      },
    },
    {
      label: "Hours: low to high",
      value: "hours asc",
      action: () => {
        this.selectedOrderBy = "hours asc";
      },
    },
    {
      label: "Hours: high to low",
      value: "hours desc",
      action: () => {
        this.selectedOrderBy = "hours desc";
      },
    },
  ] as DropDownItem[];

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedOrderBy = urlParams.get("orderBy");
  }

  getFromQueryParam() {
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedOrderBy = urlParams.get("orderBy");
    return this.selectedOrderBy;
  }

  clear() {
    this.selectedOrderBy = null;
  }
}
