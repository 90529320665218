import { animate, group, query, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { CartService } from "../../cart.service";
import { Attachment, InventoryItem, InventoryService } from "../../../inventory/inventory";
import { AzureSearchRequest, AzureSearchService } from "../../../azure-search/azure-search";
import { CartItem } from "../../models/cart.model";
import { Charge } from "../../models/charge.model";
import { AuthenticationService, DateRange, LogService } from "../../../core/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { User } from "oidc-client";
import { Account, AccountService } from "../../../account/account";

const left = [
  query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
    optional: true,
  }),
  group([
    query(":enter", [style({ transform: "translateX(-100%)" }), animate(".3s ease-out", style({ transform: "translateX(0%)" }))], {
      optional: true,
    }),
    query(":leave", [style({ transform: "translateX(0%)" }), animate(".3s ease-out", style({ transform: "translateX(100%)" }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
    optional: true,
  }),
  group([
    query(":enter", [style({ transform: "translateX(100%)" }), animate(".3s ease-out", style({ transform: "translateX(0%)" }))], {
      optional: true,
    }),
    query(":leave", [style({ transform: "translateX(0%)" }), animate(".3s ease-out", style({ transform: "translateX(-100%)" }))], {
      optional: true,
    }),
  ]),
];
@Component({
  selector: "common-machine-card-add-to-cart",
  templateUrl: "./machine-card-add-to-cart.component.html",
  styleUrls: ["./machine-card-add-to-cart.component.scss"],
  animations: [trigger("animSlider", [transition(":increment", right), transition(":decrement", left)])],
})
export class MachineCardAddToCartComponent implements OnInit {
  @Input() machine: InventoryItem;
  @Input() machineInCart: boolean;
  @Input() inCheckout: boolean;

  attachmentsFromIndex: Attachment[];
  counter: number = 0;

  steps: any[];
  attachmentsStep = {
    name: "attachments",
    header: "Select attachments",
    buttonText: "Next",
    cancelButtonText: "Cancel",
    buttonMethod: () => this.attachmentsCompleted(),
    buttonDisabled: () => {
      return false;
    },
  };

  startDateStep = {
    name: "startDate",
    header: "Start date, duration, zipcode",
    buttonText: "Review",
    cancelButtonText: "Cancel",
    buttonMethod: () => this.startDateAndDurationCompleted(),
    buttonDisabled: () => {
      return this.startDate == null || this.duration == 0;
    },
  };

  addToCartStep = {
    name: "addToCart",
    header: "Rental details",
    buttonText: "Add to Cart",
    cancelButtonText: "Cancel",
    buttonMethod: () => this.addToCartCompleted(),
    buttonDisabled: () => {
      return this.isDenied;
    },
  };

  continueCheckoutStep = {
    name: "continueCheckoutStep",
    header: "Machine added to cart",
    buttonText: "Checkout",
    cancelButtonText: "Continue Shopping",
    buttonMethod: () => this.checkout(),
    buttonDisabled: () => {
      return false;
    },
  };

  startDate: NgbDate;
  duration: number = 0;
  zipcode: string;
  cartItem: CartItem;
  loading: boolean;
  rpoInterested: boolean;
  showPhoneNumberInput: boolean;
  invalidZipCode: boolean;

  user: User;
  userAccount: Account;
  isDenied: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private azureSearchService: AzureSearchService,
    private logService: LogService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private inventoryService: InventoryService,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.user = this.authenticationService.getUserNonAsync();
    if (this.user) {
      this.accountService.get(this.user.profile.account_id).subscribe(account => {
        this.userAccount = account;
        this.isDenied = this.userAccount.verificationStatus == "Denied";
      });
    }

    this.inventoryService.counterUp(this.machine).subscribe();
    // need to filter out unavailable attachments

    // we need to set all attachments for available so the JSON serialization of cartitemstatus works. cartitemstatus only has 'available' and 'notavailable'....
    this.machine.relatedAttachments.forEach(a => {
      a.status = "Available";
    });
    if (this.inCheckout) {
      this.startDateStep.buttonText = "Save";
    }

    // if machine is in cart, it means we are editing!
    if (this.machineInCart) {
      this.addToCartStep.buttonText = "Update Cart";
      this.continueCheckoutStep.header = "Machine updated in cart";

      // we need to grab the cart item so we can set start date and duration from the cart
      const cartItem = this.cartService.getItemByInventoryItemId(this.machine.id);
      this.startDate = DateRange.createNgbDateFromString(cartItem.beginDate);
      this.duration = cartItem.duration;

      // this is for when the user is in checkout and they actually enter a job location that might have a different zip code then the one they previously entered
      this.zipcode = cartItem?.jobLocation?.address?.postalCode ? cartItem.jobLocation.address.postalCode : cartItem.jobZipCode;

      // if the cart item has attachments we need to go through and set the machine attachments to be selected and/or installed

      if (cartItem.attachments) {
        for (const cartItemAttachment of cartItem.attachments) {
          let correspondingAttachmentOnMachine = this.machine.relatedAttachments.find(d => d.inventoryItemId == cartItemAttachment.childItemId);
          if (correspondingAttachmentOnMachine) {
            correspondingAttachmentOnMachine.install = cartItemAttachment.install;
            correspondingAttachmentOnMachine.selected = true;
            correspondingAttachmentOnMachine.cost = cartItemAttachment.price;
          }
        }
      }
    } else {
      const cart = this.cartService.cart;
      if (cart.items.length != 0) {
        const lastItem = cart.items[cart.items.length - 1];
        this.startDate = DateRange.createNgbDateFromString(lastItem.beginDate);
        this.duration = lastItem.duration;
        this.zipcode = lastItem.jobZipCode;
      }
    }

    if (this.machine.relatedAttachments.length) {
      this.steps = [this.attachmentsStep, this.startDateStep, this.addToCartStep, this.continueCheckoutStep];

      const attachmentSearch = new AzureSearchRequest();
      attachmentSearch.filter = `search.in(id, '${this.machine.relatedAttachments.map(d => d.inventoryItemId).join(",")}')`;
      this.azureSearchService.search<Attachment>(attachmentSearch, "attachments").subscribe(result => {
        this.attachmentsFromIndex = result.value;
      });
    } else {
      this.steps = [this.startDateStep, this.addToCartStep, this.continueCheckoutStep];
    }
  }

  attachmentsCompleted() {
    this.counter++;
  }

  startDateSelected(date: NgbDate) {
    this.startDate = date;
  }

  durationSelected(duration: number) {
    this.duration = duration;
  }

  zipcodeChange(zipcode: string) {
    this.zipcode = zipcode;
    if (this.cartItem) {
      // since we are changing zip code we need to wipe out joblocation so that the api create a new estimate
      this.cartItem.jobLocation = null;
    }
  }

  rpoInterestedSelected(rpoInterested: boolean) {
    this.rpoInterested = rpoInterested;
  }

  startDateAndDurationCompleted() {
    this.loading = true;
    // grab estimate
    // check if start date and job duration is null. if so then just send the request with one month
    let cartItemEstimate: CartItem = new CartItem(
      DateRange.createDateFromNgbDate(this.startDate),
      this.duration,
      this.machine,
      this.machine.relatedAttachments.filter(d => d.selected && d.standard).concat(this.machine.relatedAttachments.filter(d => d.selected && !d.standard)),
      this.rpoInterested,
      this.zipcode,
    );
    cartItemEstimate.charges = [new Charge(this.machine.rentalRate, "Rent", "Monthly")];

    this.cartService.getCartItemEstimate(cartItemEstimate).subscribe((cartItem: CartItem) => {
      this.cartItem = cartItem;

      if (this.cartItem.jobZipCode == "00000") {
        this.toastrService.error("Invalid Zip Code.");
        this.zipcode = "00000";
        this.invalidZipCode = true;
        this.loading = false;
        return;
      }

      this.invalidZipCode = false;

      // if we are in checkout lets immediately update the cart item and close the modal. do not go to the review details
      if (this.inCheckout) {
        this.cartService.updateCartItem(this.cartItem, false).subscribe(() => {
          this.activeModal.close();
        });
        return;
      }

      this.counter++;
      this.loading = false;
    });
  }
  addToCartCompleted() {
    if (this.machineInCart) {
      this.cartService.updateCartItem(this.cartItem, false).subscribe();
    } else {
      const cart = this.cartService.cart;
      this.showPhoneNumberInput = this.user == null && (cart?.items?.length == 0 || cart == null);
      this.cartService.addItemToCart(this.cartItem);
    }
    this.counter++;
    // no longer slide down the modal since we want to capture their phone number now
    // document.querySelector(".modal-content").classList.add("slide-down-animation");
  }

  checkout() {
    this.logService.trackEvent("add-to-cart-checkout-click");
    this.router.navigate(["checkout"]);
    this.activeModal.close();
  }

  onPrevious() {
    this.counter--;
  }
}
