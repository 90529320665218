import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, from, of } from "rxjs";
import {
  EnvironmentConfig,
  BaseApiService,
  AuthenticationService,
  SearchResult,
  SearchModel,
  Location,
  LocationPipe,
  LocalStorageService,
  LogService,
} from "../../core/core";
import { InventoryItem, InventoryReview, Specification, Attachment } from "../inventory";
import { AssociatedItem } from "../models/associated-item.model";
import { AttachmentViewModel } from "../models/atachment-view-model.model";
import { Device } from "../models/device.model";
import { catchError, map, tap } from "rxjs/operators";
import { Operation } from "fast-json-patch";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class InventoryService extends BaseApiService<InventoryItem> {
  constructor(
    httpClient: HttpClient,
    authenticationService: AuthenticationService,
    private locationPipe: LocationPipe,
    private localStorageService: LocalStorageService,
    private logService: LogService,
  ) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/inventory", true, authenticationService);
  }

  create(model: any): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}`, model, { headers });
  }

  getWithPartitionKey(id: string, partitionKey: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${id}/details?partitionKey=${partitionKey}`, { headers });
  }

  update(id: string, model: any): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient
      .put<any>(`${this.baseUrl}${this.endpoint}/${id}/details?partitionKey=${model.accountId}`, model, { headers })
      .pipe(
        tap(result => {
          if (model.inventoryItemType == "Attachment") {
            return;
          }

          this.updateLastModifiedByOwner(id, model.accountId).subscribe();
        }),
      );
  }

  getShippingDimensions(id: string, partitionKey: string) {
    const headers = this.getAuth();
    return this.httpClient
      .get<any>(`${this.baseUrl}${this.endpoint}/${id}/shipping?accountId=${partitionKey}`, { headers })
      .pipe(
        catchError(err => {
          alert("Machine does not have shipping dimensions. Click edit and sync shipping dimensions from catalog");
          return of({});
        }),
      );
  }

  updateShippingDimensions(shippingDimensions: any) {
    const headers = this.getAuth();
    return this.httpClient.put<any>(
      `${this.baseUrl}${this.endpoint}/${shippingDimensions.inventoryItemId}/shipping?accountId=${shippingDimensions.accountId}`,
      shippingDimensions,
      { headers },
    );
  }

  getImages(inventoryId: string, accountId: string, orderByCover: boolean = false, privateImage: boolean = false) {
    const headers = this.getAuth();
    let params = new HttpParams();
    params = params.append("accountId", accountId);
    params = params.append("orderByCover", orderByCover.toString());
    params = params.append("privateImage", privateImage.toString());

    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/image`, { headers, params });
  }

  uploadImage(inventoryId: string, accountId: string, position: number, selectedFile, privateImage: boolean = false): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    let params = new HttpParams();
    params = params.append("accountId", accountId);
    params = params.append("position", position);
    params = params.append("privateImage", privateImage);

    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${inventoryId}/image`, uploadData, { headers, params }).pipe(
      tap(result => {
        this.updateLastModifiedByOwner(inventoryId, accountId).subscribe();
      }),
    );
  }

  replaceImage(inventoryId: string, accountId: string, position: number, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient
      .put(`${this.baseUrl}${this.endpoint}/${inventoryId}/image?accountId=${accountId}&position=${position}`, uploadData, { headers })
      .pipe(
        tap(result => {
          this.updateLastModifiedByOwner(inventoryId, accountId).subscribe();
        }),
      );
  }

  updateCoverImage(inventoryId: string, accountId: string, position: number) {
    const headers = this.getAuth();
    return this.httpClient
      .patch(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/cover?accountId=${accountId}&position=${position}`, {}, { headers })
      .pipe(
        tap(result => {
          this.updateLastModifiedByOwner(inventoryId, accountId).subscribe();
        }),
      );
  }

  deleteImage(inventoryId: string, accountId: string, position: number, privateImage: boolean = false) {
    const headers = this.getAuth();
    let params = new HttpParams();
    params = params.append("accountId", accountId);
    params = params.append("privateImage", privateImage);

    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/${position}`, { headers, params }).pipe(
      tap(result => {
        this.updateLastModifiedByOwner(inventoryId, accountId).subscribe();
      }),
    );
  }

  deleteImageById(imageId: string, partitionKey: string) {
    const headers = this.getAuth();
    let params = new HttpParams();
    params = params.append("partitionKey", partitionKey);

    return this.httpClient.delete(`${this.baseUrl}/api/assets/v2/image/${imageId}`, { headers, params });
  }

  getReviews(machineId: string): Observable<SearchResult<InventoryReview>> {
    const searchModel = new SearchModel();
    searchModel["hasComments"] = true;
    return this.httpClient.get<SearchResult<InventoryReview>>(`${this.baseUrl}${this.endpoint}/${machineId}/review`, { params: searchModel as any });
  }

  getEquipmentTypeInfo(): Observable<any> {
    return this.httpClient.get<SearchResult<InventoryReview>>(`${this.baseUrl}/api/assets/v1/config/public/equipmenttype`);
  }

  getAttachments(inventoryId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment`, { headers });
  }

  addAttachment(inventoryId: string, item: AssociatedItem): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment`, item, { headers }).pipe(
      tap(result => {
        this.updateLastModifiedByOwner(inventoryId, item.accountId).subscribe();
      }),
    );
  }

  updateAttachment(inventoryId: string, attachmentAssociationId: string, item: AttachmentViewModel): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${attachmentAssociationId}`, item, { headers }).pipe(
      tap(result => {
        this.updateLastModifiedByOwner(inventoryId, item.accountId).subscribe();
      }),
    );
  }

  deleteAttachment(inventoryId: string, attachmentAssociationId: string, accountId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient
      .delete(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${attachmentAssociationId}?accountId=${accountId}`, { headers })
      .pipe(
        tap(result => {
          this.updateLastModifiedByOwner(inventoryId, accountId).subscribe();
        }),
      );
  }

  getTelematics(inventoryId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/telematics`, { headers });
  }

  setTelematics(inventoryId: string, device: Device): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/telematics`, device, { headers });
  }

  getLocation(inventoryId: string): Observable<Location> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/location`, { headers });
  }

  downloadAllPhotos(inventoryId: string) {
    const headers = this.getAuth();

    const requestOptions: Object = {
      headers,
      responseType: "arraybuffer",
    };

    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/archive`, requestOptions).pipe(
      map(result => {
        let blob = new Blob([result], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      }),
    );
  }

  getFormattedSpecifications(specifications: Specification[] | any[] = []): string {
    const specNamesAndValues = specifications["flatMap"](d => d.items).map(d => {
      return `${d.name}: ${d.value}`;
    });
    return specNamesAndValues.join(", ");
  }

  machineDetailsUrl(inventoryItem: InventoryItem, includeId: boolean = true): string {
    let seourl = `/equipment-rental/${this.locationPipe.transform(inventoryItem.location, "cityState", true).replace(", ", "-")}/${inventoryItem.primaryType}/${
      inventoryItem.make
    }/${inventoryItem.model}`;

    if (includeId) {
      seourl += `?id=${inventoryItem.id}`;
    }
    return seourl;
  }

  notifyAdmin(id: string, partitionKey: string) {
    const jsonPatchOperation = [
      { op: "replace", path: "/approvalStatus", value: "Pending" },
      { op: "replace", path: "/lastUpdatedByOwnerDateTime", value: new Date() },
    ];

    const headers = this.getAuth();
    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${id}?partitionKey=${partitionKey}&sendNotification=true`, jsonPatchOperation, { headers });
  }

  approve(inventoryItemId: string, accountId: string, approvalStatus: InventoryItem["approvalStatus"], reason?: string) {
    const headers = this.getAuth();

    return this.httpClient.post<any>(
      `${this.baseUrl}${this.endpoint}/approval`,
      {
        inventoryItemId,
        accountId,
        approvalStatus,
        approvalReason: reason,
      },
      { headers },
    );
  }

  counterUp(inventoryItem: InventoryItem): Observable<any> {
    this.logService.sendGoogleEcommerceView("view_item", [inventoryItem]);

    const headers = this.getAuth();
    const localStorageKey = `machine-visit-${inventoryItem.id}`;
    if (this.localStorageService.get(localStorageKey, true)) {
      // already viewed machine
      return of(true);
    }

    this.localStorageService.set(localStorageKey, true, true);

    return this.httpClient.post<any>(`${environment.adminApiDomain}/api/admin/v1/counter/${inventoryItem.id}/up`, { machineId: inventoryItem.id }, { headers });
  }

  listCounters(inventoryItemId: string): Observable<{ count: number; date: string }> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${environment.adminApiDomain}/api/admin/v1/counter/${inventoryItemId}/list`, { headers });
  }

  jsonPatchWithPartitionKey(id: string, jsonPatchOperations: Operation[] | any[], partitionKey: string, partitionPath: string = ""): Observable<any> {
    const headers = this.getAuth();

    return this.httpClient
      .patch<any>(`${this.baseUrl}${this.endpoint}/${id}${partitionPath}?partitionKey=${partitionKey}`, jsonPatchOperations, { headers })
      .pipe(
        tap(result => {
          this.updateLastModifiedByOwner(id, partitionKey).subscribe();
        }),
      );
  }

  updateLastModifiedByOwner(id: string, partitionKey: string) {
    if (environment.clientId != "rubbl.website") {
      return of(true);
    }
    const jsonPatchOperation = [{ op: "replace", path: "/lastUpdatedByOwnerDateTime", value: new Date() }];

    const headers = this.getAuth();
    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${id}?partitionKey=${partitionKey}&sendNotification=true`, jsonPatchOperation, { headers });
  }
}
