export class Contact {
  email: string;
  name: Name | string | any;
  phoneNumber: string;
  company?: string;
  userId?: string;
  rentalRole?: string;
  accountId?: string;
}

export class Name {
  firstName: string;
  lastName: string;

  constructor(firstName: string, lastName: string) {
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
