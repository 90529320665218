import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IpGeoLocationService {
  constructor(private httpClient: HttpClient) {}

  getUserLocation() {
    const headers = { Authorization: "Bearer 23ff9d07e6248e" };
    return this.httpClient.get("https://ipinfo.io/json", { headers });
  }
}
